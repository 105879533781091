
import {defineComponent} from "vue";
import DateTimeFormat from "../../components/base/datetime/DateTimeFormat.vue";
import Avatar from "../../components/base/common/Avatar.vue";
import Pagination from "@/components/base/paging/Pagination.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ActionLogCards",
  components: { Avatar, DateTimeFormat, Pagination},
  props: {
    cardData: {type: Array, required: true},
    currentPage: {type: Number, default: 1},
    total: {type: Number, default: 0},
    rowsPerPage: {type: Number, default: 10},
  },
  emits: ['current-change'],
  setup(_, {emit}) {
    const paginationChange = (val) => {
      emit('current-change', val)
    }
    return {
      ...LoadPanel(),
      paginationChange
    }
  },
  methods: {
    toLowString(input: string) {
      return input.toLowerCase()
    }
  }
})
