
import {computed, defineComponent, ref} from "vue";
import Entities from "../../components/base/common/Entities.vue";
import {CompanySelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ActionLogCards from "@/views/actionView/ActionLogCards.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "ActionLog",
  components: {BaseSelect, DateTimeSelect, UserListSelect, ActionLogCards, Entities},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Team',
      [
        {link: false, router: '', text: 'Action Log'}
      ]
    )
    const filterObject = ref({clientId: '', userId: '', date: '', dateFrom: '', dateTo: ''})
    const page = computed(() => store.state.ActionViewModule.page);
    return {
      ...CompanySelect(),
      filterObject,
      page,
      ...LoadFilterObjects(Actions.LOAD_ACTION_VIEWS, filterObject.value, ['user'])
    }
  },
  methods: {
    discardEvent() {
      this.filterObject.clientId = ''
      this.filterObject.userId = ''
      this.updateFilterObject(this.filterObject);
    },
    searchEvent() {
      this.updateFilterObject(this.filterObject)
    }
  }
})
